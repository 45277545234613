import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Commercial Catering"
      description="Are you in need of your catering equipment to be serviced or repaired? We can help you with any catering equipment issue you might have"
      keywords="BBIS commercial catering, commercial catering repair, commercial catering equipment, catering installation, potters bar catering equipment, london catering equipment repair"
    />
    <Jumbotron imgClassName="img7" />
    <div class="white-segment text-left">
      <h1 class="heading-color">Commercial catering</h1>
      <p>
        At BBIS Commercial heating Ltd we have engineers that are fully
        qualified to work on all commercial catering equipment across London and
        Hertfordshire. We can provide you with a complete installation, service
        work or repair.
      </p>
      <p>
        We can service, repair and install fryers, convection/combi ovens, gas
        ovens and ranges. We can help if your gas oven isn’t heating up, is
        noisy, overheating, cuts out has a faulty light or controls.{" "}
      </p>
      <p>Get in touch today to arrange a call out </p>
      <p>
        Call us on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
    </div>
  </Layout>
);
